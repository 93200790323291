import React from "react";
import { Navigation } from "../components/common/navigation/navigation";
import { Footer } from "../components/sections/footer";

const NotFoundPage = () => (
  <div>
    <Navigation />
    <div className="flex flex-col items-center justify-center h-screen ">
      <h1 className="text-4xl font-medium text-gray-800">404</h1>
      <h2 className="text-2xl font-medium text-gray-800">
        Oops, it looks like you got lost!
      </h2>
      <p className="text-xl font-medium text-gray-800">
        Don't worry, we'll help you find your way.
      </p>
      <div className="my-8">
        <a
          href="/"
          className="px-4 py-2 font-medium text-white bg-purple-500 rounded-md hover:bg-purple-600"
        >
          Take me back home
        </a>
      </div>
    </div>

    <Footer />
  </div>
);

export default NotFoundPage;
